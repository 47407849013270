// http://ionicframework.com/docs/theming/
@use '@ionic/angular/css/core.css';
@use '@ionic/angular/css/normalize.css';
@use '@ionic/angular/css/structure.css';
@use '@ionic/angular/css/typography.css';

// common themes
@import 'app-header-styles';
@import 'classes/select-input';
@import 'classes/underlined-input';
@import 'focus';
@import 'fonts/open-sans/open-sans';
@import 'mea-black-primary';
@import 'mea-typography';
@import 'swiper/swiper-bundle.css';
@import 'utility-classes';

// custom themes
@import './theme/home-layout';

:root,
app-root,
ion-content,
ion-title,
ion-item,
ion-label,
div {
  line-height: var(--mea-line-height-default);
}

body,
app-root {
  font-size: var(--mea-font-size-m);
  font-weight: var(--mea-font-weight-regular) !important;
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

ion-title {
  font-size: var(--mea-font-size-m);
  font-weight: var(--mea-font-weight-bold);
}

.under-development {
  background-color: var(--under-development);
}

.under-development-outline {
  border: 2px solid var(--under-development);
}

ion-modal::part(backdrop) {
  opacity: 0.32;
}

ion-button {
  --box-shadow: none;
  text-transform: none;
  letter-spacing: unset;
}

ion-button.ion-button-text-link {
  font-size: var(--mea-font-size-m);
  font-weight: var(--mea-font-weight-bold);
  width: fit-content;
  min-width: fit-content;

  --transition: color 0.3s ease-out;
  --min-width: 0;
  --background: transparent;
  --color: var(--mea-green-primary);
  --color-hover: var(--mea-green-100);
  --padding-start: 0;
  --padding-end: 0;

  &[disabled] {
    --color: var(--mea-black-500);
  }

  &.small {
    font-size: var(--mea-font-size-s);
    min-height: calc(var(--mea-font-size-s) * var(--mea-line-height-default));
    margin: 0;
    --padding-bottom: 0;
    --padding-top: 0;
  }
}

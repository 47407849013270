.home-grid {
  padding: var(--mea-spacing-60px) 0 calc(var(--mea-spacing-60px) + var(--mea-spacing-40px));
  width: var(--mea-content-width);
  margin: 0 auto;

  display: grid;
  grid-template-columns: minmax(var(--mea-min-content-column-width), auto) var(--mea-min-overview-column-width);
  gap: var(--mea-spacing-24px) var(--mea-spacing-40px);

  mea-back-button {
    --min-height: var(--mea-spacing-32px); // aligning with tag height
  }

  .headline {
    grid-column: span 2;
  }

  &:has(mea-back-button) {
    padding: var(--mea-spacing-24px) 0 calc(var(--mea-spacing-60px) + var(--mea-spacing-40px));

    mea-back-button {
      margin-bottom: var(--mea-spacing-32px);
    }
  }
}

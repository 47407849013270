// screen sizes
$small: 576px;
$medium: 768px;
$large: 992px;
$larger: 1280px;
$extra-large: 1500px;

// mea corporate design
:root {
  /** text **/
  --mea-line-height-button: 52px;
  --mea-line-height-medium: 27px;
  --mea-line-height-small: 22px;
  --mea-line-height-default: 1.3;

  /** font **/
  --ion-dynamic-font: initial; // not recommended by Ionic, disables dynamic font scaling
  --ion-font-family: 'Open Sans';

  --mea-font-size-xxl: 34px;
  --mea-font-size-xl: 26px;
  --mea-font-size-l: 20px;
  --mea-font-size-m: 16px;
  --mea-font-size-sm: 14px;
  --mea-font-size-s: 12px;

  --mea-font-size-icon-small: 20px;
  --mea-font-size-icon: 24px;
  --mea-font-size-icon-large: 30px;

  --mea-font-weight-extra-bold: 800;
  --mea-font-weight-bold: 700;
  --mea-font-weight-regular: 400;

  /** border-radius **/
  --mea-border-radius-interactive: 7px;
  --mea-border-radius-presentational: 7px;

  --mea-border-radius-large: 20px;
  --mea-border-radius-extra-large: 30px;
  --mea-border-radius-input: 26px;
  --mea-border-radius-sheet-modal: 38px;
  --mea-border-radius-circular: 1000px; // this value applies a circular border radius also to smaller elements

  /** sizing **/
  --mea-input-height-large: 52px;
  --mea-input-height-textarea: 223px;
  --mea-textarea-height-default: calc(
    3 * var(--mea-line-height-default) * var(--mea-font-size-m) + 2 * var(--mea-spacing-16px)
  );

  --mea-button-min-width: 150px;
  --mea-button-fancy-width: 346px;

  --mea-size-interactive: 44px;
  --mea-size-checkbox: 20px;

  /** spacing **/
  --mea-padding-default: 12px;

  --mea-spacing-2px: 2px;
  --mea-spacing-4px: 4px;
  --mea-spacing-8px: 8px;
  --mea-spacing-12px: 12px;
  --mea-spacing-16px: 16px;
  --mea-spacing-20px: 20px;
  --mea-spacing-24px: 24px;
  --mea-spacing-32px: 32px;
  --mea-spacing-40px: 40px;
  --mea-spacing-60px: 60px;
  --mea-spacing-34px: 34px;

  /** miscellaneous **/
  --mea-backdrop-opacity-sheet-modal: 0.7;
  --pharmacy-logo-size-chat: 30px;

  /** page and content dimensions **/
  --mea-max-page-width: 1200px;
  --mea-min-content-column-width: 510px;
  --mea-max-content-column-width: calc(
    var(--mea-max-page-width) - var(--mea-min-overview-column-width) - var(--mea-spacing-40px)
  );
  --mea-min-overview-column-width: 356px;
  --mea-content-width: min(calc(100vw - 2 * var(--mea-spacing-60px)), var(--mea-max-page-width));
  --mea-min-page-width: calc(
    var(--mea-min-content-column-width) + var(--mea-min-overview-column-width) + var(--mea-spacing-40px) +
      calc(2 * var(--mea-spacing-60px))
  );
}

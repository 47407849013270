ion-input.ion-input-search-input {
  font-weight: var(--mea-font-weight-bold);
  --placeholder-color: var(--mea-black-500);
  --placeholder-opacity: 1;

  // clear input button
  button ion-icon {
    font-size: var(--mea-font-size-icon-large);
    color: var(--mea-black-primary);
  }

  div[slot='label']:has(ion-icon) {
    display: flex;

    ion-icon {
      font-size: min(2rem, var(--mea-font-size-icon-small));
      padding-right: var(--mea-spacing-16px);
    }
  }
}

@import '../variables';
@import '../mea-typography';

@mixin button-header-styling {
  // necessary for compatibility with mea-icon-buttons in old headers / modals used in companion- & mea-app
  mea-icon-button {
    background: var(--mea-black-600);
    transition: background 0.3s ease-out;

    &:hover {
      color: var(--mea-white);
      background: var(--mea-black-primary);
    }
  }

  ion-button[modalHeaderButton] {
    --border-radius: 50%;
    opacity: 1;

    &[disabled] {
      opacity: 0.5;
    }

    &::part(native) {
      height: var(--mea-spacing-40px);
      width: var(--mea-spacing-40px);

      color: var(--mea-black-primary);
      background: var(--mea-black-600);
      transition: background 0.3s ease-out;

      &:hover {
        color: var(--mea-white);
        background: var(--mea-black-primary);
      }
    }

    &:not(:last-of-type) {
      margin-right: var(--mea-spacing-16px);
    }

    ion-icon[slot='icon-only'] {
      font-size: var(--mea-font-size-icon-small);
    }

    // overrides for Ionic MD-Styling
    &.sc-ion-buttons-md {
      --padding-top: 0;
      --padding-end: 0;
      --padding-bottom: 0;
      --padding-start: 0;
      width: auto;
      height: auto;
    }
  }

  ion-button[modalHeaderButton][icon='arrow_left'] {
    margin-left: calc(-1 * 14px);

    &::part(native) {
      height: var(--mea-spacing-40px);
      width: var(--mea-spacing-40px);

      color: var(--mea-black-primary);
      background-color: transparent;
      transition:
        color 0.3s ease-out,
        background-color 0.3s ease-out;

      &:hover {
        color: var(--mea-black-100);
        background-color: var(--mea-black-800);
      }
    }
  }
}

@mixin large-toolbar-styling {
  ion-toolbar {
    --background: transparent;
    --border-style: none;
    --padding-start: var(--mea-spacing-40px);
    --padding-end: var(--mea-spacing-40px);
    --padding-top: var(--mea-spacing-40px);
    --padding-bottom: 0;

    &:has(ion-title) {
      --padding-bottom: var(--mea-spacing-24px);
    }

    ion-title {
      @include mea-headline-l;
    }

    // override ios ionic style with 8px padding
    padding-right: var(--ion-safe-area-right) !important;
    padding-left: var(--ion-safe-area-left) !important;

    @include button-header-styling;
  }
}

ion-modal {
  &.auto-height {
    --height: auto;
    --max-height: 100%;

    .ion-page {
      position: relative;
      display: block;
      contain: content;
    }

    ::part(content) {
      overflow: auto;
    }
  }

  &.auto-width {
    --width: auto;
    --max-width: 100%;

    .ion-page {
      position: relative;
      display: flex;
      justify-content: flex-start;
      contain: content;
    }

    ::part(content) {
      overflow: auto;
    }
  }

  &.full-screen-modal {
    --height: 100%;
    --width: 100%;
  }

  &.fancy-small {
    --width: 867px;
    --height: fit-content;
    --overflow: visible;
    --border-radius: var(--mea-border-radius-presentational);
    --box-shadow: var(--mea-box-shadow-fancy);

    @include button-header-styling;
    ion-button[modalHeaderButton] {
      position: fixed;
      top: calc(-1 * var(--mea-spacing-12px));
      right: calc(-1 * var(--mea-spacing-12px));
    }

    @media only screen and (width < $large) {
      --width: 100%;
      --height: 100%;
      --border-radius: 0;
      --box-shadow: 0;

      .modal {
        margin-top: var(--mea-spacing-24px);
      }

      ion-button[modalHeaderButton] {
        top: var(--mea-spacing-12px);
        right: var(--mea-spacing-12px);
      }
    }
  }

  &.fancy-large {
    --width: 1100px;
    --height: 750px;
    --border-radius: var(--mea-border-radius-presentational);
    --box-shadow: var(--mea-box-shadow-fancy);

    @include large-toolbar-styling;

    &.flex-size {
      --height: auto;
      --width: auto;
    }

    &.medium-width {
      --width: 879px;
    }

    ion-content {
      --padding-start: var(--mea-spacing-32px);
      --padding-end: var(--mea-spacing-32px);
      --padding-top: 0;
      --padding-bottom: var(--mea-spacing-32px);
    }

    @media only screen and (width < $large) {
      --width: 100%;
      --height: 100%;
      --border-radius: 0;
      --box-shadow: 0;
    }
  }

  &.fancy-fullscreen {
    --width: 100%;
    --height: 100%;

    @include large-toolbar-styling;
  }

  &.ion-sheet-modal-auto-height {
    --height: auto;
    --background: transparent;
  }

  &.right-edge {
    --height: 100%;
    --width: 420px;
    justify-content: flex-end;
    @include large-toolbar-styling;
  }

  &.product-search-modal {
    --min-height: 586px;
    --max-width: 100%;
    --width: 832px;
  }

  &.video-modal {
    --width: 70%;
  }

  &.mea-alert {
    --height: auto;
    --width: 400px;

    .ion-page {
      position: relative;
      display: block;
      contain: content;
    }
  }

  &.chat-image-slides-modal {
    @media only screen and (min-width: $medium) {
      --width: 100%;
      --height: 100%;
    }
  }

  &.dataconsent-modal {
    @media only screen and (min-width: $medium) {
      --border-radius: var(--mea-border-radius-presentational);
    }
  }

  &.legal-app {
    ion-content {
      --padding-start: var(--mea-spacing-16px);
      --padding-end: var(--mea-spacing-16px);
      --padding-top: var(--mea-spacing-8px);
      --padding-bottom: var(--mea-spacing-40px);
    }

    ::ng-deep li {
      word-break: break-word;
    }

    ::ng-deep li:before {
      word-break: normal;
    }
  }
}

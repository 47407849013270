@mixin mea-headline-l {
  font-size: var(--mea-font-size-l);
  font-weight: var(--mea-font-weight-bold);
}

.mea-headline-xl {
  font-size: var(--mea-font-size-xl);
  font-weight: var(--mea-font-weight-bold);
}

.mea-headline-l,
.mea-title-headline-l {
  @include mea-headline-l;
}

.mea-headline-l-extra-bold {
  @include mea-headline-l;
  font-weight: var(--mea-font-weight-extra-bold);
}

.mea-title-headline-l,
.mea-title-alignment {
  margin: var(--mea-spacing-16px) 0 var(--mea-spacing-16px);
  padding-inline: var(--mea-spacing-16px);
}

.mea-headline-m {
  font-size: var(--mea-font-size-m);
  font-weight: var(--mea-font-weight-bold);
}

.mea-headline-m-extra-bold {
  font-size: var(--mea-font-size-m);
  font-weight: var(--mea-font-weight-extra-bold);
}

.mea-headline-s {
  font-size: var(--mea-font-size-s);
  font-weight: var(--mea-font-weight-bold);
}

.mea-label-l {
  font-size: var(--mea-font-size-l);
  font-weight: var(--mea-font-weight-regular);
}

.mea-label-m {
  font-size: var(--mea-font-size-m);
  font-weight: var(--mea-font-weight-regular);
}

.mea-label-s {
  font-size: var(--mea-font-size-s);
  font-weight: var(--mea-font-weight-regular);
}

.mea-text-m {
  font-size: var(--mea-font-size-m);
  font-weight: var(--mea-font-weight-regular);
  line-height: var(--mea-line-height-medium);
}

.mea-text-m-bold {
  font-size: var(--mea-font-size-m);
  font-weight: var(--mea-font-weight-bold);
  line-height: var(--mea-line-height-medium);
}

.mea-text-m-extra-bold {
  font-size: var(--mea-font-size-m);
  font-weight: var(--mea-font-weight-extra-bold);
  line-height: var(--mea-line-height-medium);
}

.mea-text-m-italic {
  font-size: var(--mea-font-size-m);
  font-weight: var(--mea-font-weight-regular);
  line-height: var(--mea-line-height-medium);
  font-style: italic;
}

.mea-text-s {
  font-size: var(--mea-font-size-s);
  font-weight: var(--mea-font-weight-regular);
  line-height: var(--mea-line-height-small);
}

.mea-text-s-bold {
  font-size: var(--mea-font-size-s);
  font-weight: var(--mea-font-weight-bold);
  line-height: var(--mea-line-height-small);
}

a.email-link {
  color: var(--mea-green-primary);
  transition: color 0.3s ease-out;

  &:hover {
    color: var(--mea-green-100);
  }

  &.bold {
    text-decoration: none;
    font-weight: var(--mea-font-weight-bold);
  }
}

a.inline-link {
  text-decoration: none;
  color: var(--mea-green-primary);
  transition: color 0.3s ease-out;

  &:hover {
    color: var(--mea-green-100);
  }
}

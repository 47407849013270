@import './ion-components/ion-modal';

.app-modal-header {
  ion-toolbar {
    @include button-header-styling;

    --background: transparent;
    --border-style: none;
    --padding-start: var(--mea-spacing-8px);
    --padding-end: var(--mea-spacing-8px);
    --padding-top: var(--mea-spacing-8px);
    --padding-bottom: var(--mea-spacing-8px);
    // override ios ionic style with 8px padding
    padding-right: var(--ion-safe-area-right) !important;
    padding-left: var(--ion-safe-area-left) !important;

    mea-icon-button {
      margin: 0 var(--mea-spacing-8px) 0 0;
      background: var(--mea-black-600);
      border-radius: 50%;

      ion-button {
        margin: 0; // necessary for widget to avoid oval buttons
      }
    }
  }
}

:root {
  &.ion-palette-dark {
    .app-modal-header {
      ion-toolbar {
        mea-icon-button {
          background: var(--mea-white);

          &.back-button {
            background: var(--mea-background);
          }
        }
      }
    }
  }
}

.app-modal-header-back-button {
  // set border in ion-toolbar to be included in ionic animations
  ion-toolbar {
    --border-color: var(--mea-black-500);
    --border-style: none none solid none;
    --border-width: 1px !important;
  }

  ion-back-button {
    --icon-font-size: var(--mea-font-size-icon-small);
    --padding-end: var(--mea-spacing-8px);
    --padding-start: var(--mea-spacing-8px);
    margin-left: var(--mea-spacing-4px);
    height: calc(var(--mea-font-size-icon-small) + var(--mea-spacing-8px) * 2);
    min-width: var(--mea-font-size-icon-small);
  }

  ion-title {
    padding-inline: 0;
  }
}

ion-popover.chat-messages-popover {
  --width: '*';
  --backdrop-opacity: 0.5;
  backdrop-filter: blur(4px);
}
